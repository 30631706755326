import React from 'react';
import MusicPlayer from '../components/MusicPlayer';

const Index = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <MusicPlayer />
    </div>
  );
};

export default Index;
